<template>
    <div class="tech-specs">
        <tabs></tabs>
        <!-- <div class="tech-specs-header">
            <div class="tech-specs-header-tabs" ref="scroll">
                <a href="javascript:;" class="poppins tech-specs-header-tab"></a>
            </div>
        </div> -->
        <router-view></router-view>
    </div>
</template>

<script>
import tabs from './comp/tabs.vue';
export default {
    name: 'techSpecs',
    components: {
        tabs,
    },
    data() {
        return {
            current: this.$route.params.product,
        };
    },
    computed: {
        i18nPage() {
            return this.$t('qaInfoPage');
        },
        tabs() {
            var { i18nPage } = this;
            return [
                {
                    name: i18nPage.eveM,
                    key: 'eveM',
                },
                {
                    name: i18nPage.meitukey,
                    key: 'meitukey',
                },
                // {
                //     name: i18nPage.meituGenius,
                //     key: 'meituGenius',
                // },
            ];
        },
    },
};
</script>
